body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hover-content-container .hover-content {
    opacity: 0;
    transition: opacity 0.3s;
}

.hover-content-container:hover .hover-content {
    opacity: 100;
    transition: opacity 0.1s;
}

.scrollable-text p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scrollable-text:hover {
    overflow-y: auto;
}

.scrollable-text:hover p {
    -webkit-line-clamp: unset;
    text-overflow: clip; /* Change to 'clip' to remove ellipsis during hover */
    overflow-y: auto;
}

.collected-data {
    padding: 0px 2px;
    border-radius: 4px;
    border: 1px solid #5588c7;
    background-color: #adcbee;
}

.uncollected-data {
    padding: 0px 2px;
    border-radius: 4px;
    border: 1px solid #707070;
    background-color: #cccccc;
}

.draggable-input-area {
    background-color: #787878 !important;
}

.draggable-input-area > *:not(.draggable-element):not(.draggable-ignored),
.draggable-input-area > *:not(.draggable-ignored) > *:not(.draggable-element):not(.draggable-ignored),
.draggable-input-area > *:not(.draggable-ignored) > *:not(.draggable-ignored) > *:not(.draggable-element):not(.draggable-ignored),
.draggable-input-area > *:not(.draggable-ignored) > *:not(.draggable-ignored) > *:not(.draggable-ignored) > *:not(.draggable-element):not(.draggable-ignored),
.draggable-input-area
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-element):not(.draggable-ignored),
.draggable-input-area
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-element):not(.draggable-ignored),
.draggable-input-area
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-element):not(.draggable-ignored),
.draggable-input-area
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-element):not(.draggable-ignored),
.draggable-input-area
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-element):not(.draggable-ignored),
.draggable-input-area
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-ignored)
    > *:not(.draggable-element):not(.draggable-ignored) {
    background: none;
    color: #58585a;
    border-color: none;
}

.draggable-input-area .non-draggable-element {
    pointer-events: none;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 5px 5px #5588c7;
    }
    50% {
        box-shadow: 0 0 5px 5px #ffffff;
    }
    100% {
        box-shadow: 0 0 5px 5px #5588c7;
    }
}

.draggable-input-area .draggable-element {
    background-color: #fafafa;
    animation: pulse 1s infinite;
}
